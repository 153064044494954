import { defineStore } from 'pinia'
import type { IUser } from '~/types/models/user.interface'

export const useUserStore = defineStore('user', {
	state: () =>
		({
			user: null,
		} as { user: IUser | null }),
	getters: {
		isLoggedIn: state => !!state.user,
	},
	actions: {
		updateUsage(permission: PlanPermissions, usage: number) {
			if (!this.user) return

			this.user.subscription.usage[permission] = usage
		},
		login(user: IUser) {
			this.user = user
		},
		logout() {
			this.user = null
		},
	},
})
